<template>
  <div class="home">
    <div v-if="reviewExists">
      <p class="thank-you">
        Am primit deja feedback-ul dumneavoastra dupa ultima vizita la clinica D`Institute.
        Va multumim si asteptam sa ne revedem la urmatoarea consultatie!
      </p>
    </div>
    <div v-else>
      <h1 v-if="inReview">
        <img src="https://dinstitute.ro/wp-content/uploads/2018/10/logo_transparent-240.png" alt="logo dinstitute">
        Salut!
        <br>
        Noi, echipa D'Institute, ținem mult să ne facem treaba perfect, și la fel de mult
        să ai o experiență cel puțin la fel de friendly (sau prietenoasă) ca sloganul nostru.
        <br>
        Te rugăm să ne acorzi două minute din timpul tău, pentru a ști dacă suntem pe drumul cel bun!
      </h1>

      <ul v-if="inReview">
        <li v-for="({ question }, index) in questions"
            :key="index">
          <div v-show="index < 4">
            <h2>{{ `${index + 1}. `}} {{ question }}</h2>
            <Rating :grade="0"
                    :questionIndex="index"
                    :maxStars="5"
                    :hasCounter="false"
                    @starRating="rate"
            />
            <textarea v-model="questions[index].text"
                      :class="{'visible-textbox': questions[index].rating < 3}"
                      placeholder="adauga te rog cateva detalii"
                      maxlength="400"
            />
          </div>
          <div v-show="index === 4">
            <h2>{{ `${index + 1}. `}} {{ question }}</h2>
            <textarea v-model="questions[index].text"
                      class="visible-textbox"
                      placeholder="adauga te rog cateva detalii"
                      maxlength="400"
            />
          </div>
        </li>
        <li>
          <br>
          <h2>Dacă vrei totuși să ne transmiți ceva, te rugăm să ne scrii în căsuța de mai jos.</h2>
          <textarea v-model="questions[5].text"
                    class="visible-textbox"
                    placeholder="adauga te rog cateva detalii"
                    maxlength="400"
          />
        </li>
        <li>
          <br>
          <button @click="sendReview"
                  :disabled="invalid">Trimite</button>
        </li>
      </ul>

      <p v-else class="thank-you">
        Chestionar trimis cu succes.
        <br>
        Va multumim pentru timpul acordat!
      </p>
    </div>
  </div>
</template>

<script>
  import Rating from '../components/Rating.vue'
  export default {
    name: 'Home',
    components: {
      Rating
    },
    data () {
      return {
        questions: [
          {
            question: 'Cum te-ai simțit la noi în clinică?',
            rating: undefined,
            text: ''
          },
          {
            question: 'Ce impresie ți-au făcut fetele vesele de la Recepție?',
            rating: undefined,
            text: ''
          },
          {
            question: 'Cât de mulțumit(ă) ești de serviciile medicale primite?',
            rating: undefined,
            text: ''
          },
          {
            question: 'Cât de mulțumit(ă) esti de medicul care ți-a redat zâmbetul (aici poți include orice tip de tratament)?',
            rating: undefined,
            text: ''
          },
          {
            question: 'Numește un singur lucru pe care ți l-ai dori în clinica noastră și de care noi poate am uitat (poate mult prea preocupați de standardele din stomatologie)',
            text: ''
          },
          {
            question: 'Dacă vrei totuși să ne transmiți ceva, te rugăm sa ne scrii în căsuța de mai jos.',
            text: ''
          }
        ]
      }
    },
    created () {
      this.$store.dispatch('check_patient_for_review', { id: this.$router.currentRoute.query.id });
    },
    computed: {
      invalid () {
        return this.questions.every(o => !o.rating) && !this.questions[4].text && !this.questions[5].text;
      },
      inReview () {
        return this.$store.state.inReview;
      },
      reviewExists () {
        return this.$store.state.reviewExistsInLessThenSevenDays;
      }
    },
    methods : {
      rate ({ rating, questionIndex }) {
        this.questions[questionIndex].rating = rating;
        rating > 2 && (this.questions[questionIndex].text = '');
      },
      sendReview () {
        const id = this.$router.currentRoute.query.id;
        this.$store.dispatch('send_feedback', {
          questions: this.questions,
          id
        })
      }
    }
  }
</script>

<style lang="scss">
  .home {
    @media only screen and (min-width: 0) {
      text-align: center;
      padding: 42px 0 120px;
      h1 {
        font-size: 1.8rem;
        line-height: 1.4;
        color: #232323;
        font-weight: 400;
        max-width: 86%;
        margin: 0 auto 50px;
        img {
          display: block;
          width: 120px;
          margin: 0 auto 12px;
        }
      }
      h2 {
        max-width: 600px;
        margin: 22px auto 12px;
        padding: 0 12px;
        font-size: 1.7rem;
        line-height: 1.4;
        font-weight: 400;
      }
      textarea {
        width: 100%;
        max-width: 280px;
        height: 0;
        padding-left: 8px;
        visibility: hidden;
        opacity: 0;
        border: 1px solid #a8a8a8;
        border-radius: 2px;
        transition: all .2s ease-in-out;
        font-size: 16px;
      }
      & > div > ul {
        > li {
          margin-bottom: 8px;
          font-size: 2rem;
        }
        > li:last-child {
          margin-top: 24px;
        }
        button {
          outline: none;
          border: none;
          padding: 10px 34px;
          border-radius: 4px;
          font-size: 18px;
          background: #677a67;
          color: white;
          cursor: pointer;
        }

        button[disabled] {
          background: lightgray!important;
          cursor: default;
        }
      }
      ul + p {
        padding: 0 26px;
        font-size: 2.2rem;
      }
      .confirmation-modal {
        margin: 0 auto;
        border-radius: 10px;
        border: 1px solid grey;
        width: 300px;
        height: 40px;
      }
      textarea.visible-textbox {
        height: 150px;
        padding-top: 8px;
        opacity: 1;
        visibility: visible;
      }

      p.thank-you {
        padding: 0 18px;
        font-size: 2.2rem;
      }
      .rating-module {
        margin-bottom: 16px;
      }
    }

    @media only screen and (min-width: 1024px) {
      h1 {
        max-width: 570px;
      }
      textarea {
        max-width: 480px;
      }
    }
  }
</style>

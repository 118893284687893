<template>
  <div class="rating-module">
    <ul class="list">
      <li @click="rate(star)"
          class="star"
          :class="{'active': +star <= +rating}"
          v-for="(star, i) in maxStars"
          :key="star">
        <i class="fa-star"
           :class="+star <= +rating ? 'fas' : 'far'"/>
      </li>
    </ul>
    <div v-if="hasCounter"
         class="info counter">
      <span class="score-rating">{{ stars }}</span>
      <span class="divider">/</span>
      <span class="score-max">{{ maxStars }}</span>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Rating',
    props: ['grade', 'maxStars', 'hasCounter', 'questionIndex'],
    data () {
      return {
        stars: this.grade,
        rating: undefined
      }
    },
    methods: {
      rate (star) {
        this.$emit('starRating', {rating: star, questionIndex: this.questionIndex})
        this.rating = star;
      }
    }
  }
</script>

<style lang="scss">
  .rating-module {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #b7b7b7;

    .list {
      padding: 0;

      &:hover {
        .star {
          color: #3F3F3F;
        }
      }

      .star {
        display: inline-block;
        font-size: 28px;
        transition: all .2s ease-in-out;
        cursor: pointer;

        &:hover {
          ~ .star:not(.active) {
            color: inherit;
          }
        }

        &:first-child {
          margin-left: 0;
        }

        &.active {
          color: #fab60b;
        }
      }

      .fa-star {
        margin-right: 4px;
      }
    }
  }
</style>

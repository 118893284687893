import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    inReview: true,
    reviewExistsInLessThenSevenDays: false
  },
  mutations: {
  },
  actions: {
    async send_feedback ({ state }, { questions, id }) {
      try {
        const url = `https://dinstituteapp.ro/api/reviews?id=${id}`;
        // const url = `http://192.168.8.100:5011/reviews?id=${id}`;
        await axios.post(url, { questions });
        Vue.set(state, 'inReview', false);
      }
      catch (err) {
        console.log(err);
      }
    },
    async check_patient_for_review ({ state }, { id }) {
      try {
        const url = `https://dinstituteapp.ro/api/reviews/check-review?id=${id}`
        // const url = `http://192.168.8.100:5011/reviews/check-review?id=${id}`;
        await axios(url);
      }
      catch (err) {
        const status = err.response.status;
        Vue.set(state, 'reviewExistsInLessThenSevenDays', status === 303);
      }
    }
  },
  modules: {
  }
})
